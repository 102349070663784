import { useEffect } from 'react';

export type ScrollListenerHandler = (event: {
  scrollTop: number;
  documentHeight: number;
  y: number;
  target: { documentElement: HTMLElement };
}) => any;

const useScrollListener = (onScroll: ScrollListenerHandler | undefined) => {
  useEffect(() => {
    if (!onScroll) {
      return;
    }

    window?.addEventListener('scroll', onScroll as any, false);

    return () => {
      window?.removeEventListener('scroll', onScroll as any, false);
    };
  }, [onScroll]);
};

export default useScrollListener;
