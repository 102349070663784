import { trimEnd } from 'lodash';
import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { getPostCover as _getPostCover, getPostVideoEmbedCover as _getPostVideoEmbedCover, } from '@wix/communities-universal/dist/src/services/get-post-cover';
import { POST_SEO_DESCRIPTION_MAXLENGTH } from '@wix/communities-blog-universal/dist/src/constants/post';
import { getVideoUrl, limitLength, removeTrailingSlash, resizeThumbnail, } from '../../services';
import { getMultilingualQueryParam } from '../../services/get-multilingual-query-param';
export { getPostImageCover } from '@wix/communities-universal/dist/src/services/get-post-cover';
var getHelpers = function (imageHost, videoHost) { return ({
    resizeThumbnail: function (pathname, width, height) {
        return resizeThumbnail({ pathname: pathname, width: width, height: height, imageHost: imageHost });
    },
    getVideoUrl: function (pathname) { return getVideoUrl({ pathname: pathname, videoHost: videoHost }); },
}); };
export var getPostCover = function (post, imageHost, videoHost) {
    var _a;
    return imageHost && videoHost
        ? _getPostCover(post, getHelpers(imageHost, videoHost))
        : (_a = post === null || post === void 0 ? void 0 : post.coverImage) !== null && _a !== void 0 ? _a : {};
};
export var getPostVideoEmbedCover = function (post, imageHost, videoHost) {
    return _getPostVideoEmbedCover(post, getHelpers(imageHost, videoHost));
};
export var getPostExcerpt = function (_a) {
    var content = _a.content, excerpt = _a.excerpt;
    return limitLength(excerpt || getContentText(content), POST_SEO_DESCRIPTION_MAXLENGTH);
};
export var getPostSeoDescription = function (_a) {
    var seoDescription = _a.seoDescription, content = _a.content, excerpt = _a.excerpt;
    return limitLength(seoDescription ||
        excerpt ||
        getContentText(content, POST_SEO_DESCRIPTION_MAXLENGTH), POST_SEO_DESCRIPTION_MAXLENGTH);
};
export var getPostAmpUrl = function (postId, ampBaseUrl, postSlug) {
    if (!ampBaseUrl || !postId) {
        return;
    }
    return "".concat(removeTrailingSlash(ampBaseUrl), "/").concat(removeTrailingSlash(postSlug));
};
export var getCanonicalPostUrl = function (_a) {
    var post = _a.post, postPageSectionUrl = _a.postPageSectionUrl, customPostUrl = _a.customPostUrl, multilingual = _a.multilingual;
    if (customPostUrl) {
        var langParam = getMultilingualQueryParam(multilingual);
        return "".concat(customPostUrl).concat(langParam ? langParam : '');
    }
    if (post.url) {
        return "".concat(post.url.base).concat(post.url.path);
    }
    if (post.canonicalUrl) {
        return post.canonicalUrl;
    }
    return "".concat(trimEnd(postPageSectionUrl, '/'), "/").concat(post.slug);
};
